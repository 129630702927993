import { Pinia, Store } from 'pinia-class-component'

import { Environment } from '@jouzen/outo-apps-toolkit'

import { SampleFilter, ScheduleAlert, ScheduleAlertRule, ScheduleData, ScheduleTask } from '#types'

@Store
export class SetupStore extends Pinia {
  public loading = false

  public includeArchived = false

  public sampleFilters: SampleFilter[] = []
  public scheduleTasks: ScheduleTask[] = []
  public scheduleAlerts: ScheduleAlert[] = []

  public sampleFilterDetails: SampleFilter | null = null

  public activeScheduleAlert: ScheduleAlert | null = null

  public updateIncludeArchived(value: boolean) {
    this.includeArchived = value
  }

  // Sample filters

  @Environment()
  public async listSampleFilters(env: string) {
    this.loading = true

    this.sampleFilters = []

    const response = await this.$axios.get('/api/v1/tasks/samples/filters', {
      apiEnv: env,
      params: {
        includeArchived: this.includeArchived || false,
      },
    })

    if (response?.data) {
      this.sampleFilters = response?.data
    }

    this.loading = false
  }

  @Environment()
  public async fetchSampleFilter(env: string, uid: string) {
    this.loading = true

    this.sampleFilterDetails = null

    const response = await this.$axios.get(`/api/v1/tasks/samples/filters/${uid}`, { apiEnv: env })

    const sampleFilterDetails = response?.data ?? null

    this.sampleFilterDetails = sampleFilterDetails

    this.loading = false

    return sampleFilterDetails
  }

  @Environment()
  public async deleteSampleFilter(env: string, uid: string) {
    this.loading = true

    const response = await this.$axios.delete(`/api/v1/tasks/samples/filters/${uid}`, { apiEnv: env })

    this.loading = false

    this.listSampleFilters(env)

    return response
  }

  @Environment()
  public async createSampleFilter(env: string, data: any) {
    this.loading = true

    const response = await this.$axios.post('/api/v1/tasks/samples/filters', data, { apiEnv: env })

    this.loading = false

    this.listSampleFilters(env)

    return response
  }

  @Environment()
  public async modifySampleFilter(env: string, data: any) {
    this.loading = true

    const response = await this.$axios.put(`/api/v1/tasks/samples/filters/${data.uid}`, data, { apiEnv: env })

    this.loading = false

    this.listSampleFilters(env)

    return response
  }

  // Schedule tasks

  @Environment()
  public async listScheduleTasks(env: string) {
    this.loading = true

    const response = await this.$axios.get(`/api/v1/tasks/schedule`, { apiEnv: env })

    this.scheduleTasks = response?.data?.rules ?? []

    this.loading = false
  }

  @Environment()
  public async deleteScheduleTask(env: string, uid: string) {
    this.loading = true

    const response = await this.$axios.delete(`/api/v1/tasks/schedule/${uid}`, { apiEnv: env })

    this.loading = false

    this.listScheduleTasks(env)

    return response
  }

  @Environment()
  public async createScheduleTask(env: string, schedule: ScheduleData) {
    this.loading = true

    schedule.taskName = 'poirot-analyze-firmware'

    const response = await this.$axios.post(`/api/v1/tasks/schedule`, schedule, { apiEnv: env })

    this.loading = false

    this.listScheduleTasks(env)

    return response
  }

  @Environment()
  public async modifyScheduleTask(env: string, uid: string, schedule: Partial<ScheduleData>) {
    this.loading = true

    const response = await this.$axios.put(`/api/v1/tasks/schedule/${uid}`, schedule, { apiEnv: env })

    this.loading = false

    this.listScheduleTasks(env)

    return response
  }

  // Schedule alerts

  @Environment()
  public async listScheduleAlerts(env: string) {
    this.loading = true

    const response = await this.$axios.get('/api/v1/tasks/rulesets', { apiEnv: env })

    this.scheduleAlerts = response?.data ?? []

    this.loading = false
  }

  @Environment()
  public async fetchScheduleAlert(env: string, uid: string) {
    this.loading = true

    this.activeScheduleAlert = null

    const response = await this.$axios.get(`/api/v1/tasks/rulesets/${uid}`, { apiEnv: env })

    this.activeScheduleAlert = response?.data

    this.loading = false

    return response
  }

  @Environment()
  public async deleteScheduleAlert(env: string, uid: string) {
    this.loading = true

    const response = await this.$axios.delete(`/api/v1/tasks/rulesets/${uid}`, { apiEnv: env })

    this.loading = false

    this.listScheduleAlerts(env)

    return response
  }

  @Environment()
  public async createScheduleAlert(env: string, data: ScheduleAlert) {
    this.loading = true

    const response = await this.$axios.post(
      '/api/v1/tasks/rulesets',
      {
        name: data.name,
        description: data.description,
      },
      { apiEnv: env },
    )

    this.loading = false

    this.listScheduleAlerts(env)

    return response
  }

  @Environment()
  public async modifyScheduleAlert(env: string, data: ScheduleAlert) {
    this.loading = true

    const response = await this.$axios.put(
      `/api/v1/tasks/rulesets/${data.uid}`,
      {
        name: data.name,
        description: data.description,
      },
      { apiEnv: env },
    )

    this.loading = false

    this.listScheduleAlerts(env)

    return response
  }

  // Schedule alert rules

  @Environment()
  public async addScheduleAlertRule(env: string, data: ScheduleAlertRule) {
    this.loading = true

    const response = await this.$axios.post(
      `/api/v1/tasks/rulesets/${data.uid}/rules`,
      {
        rule: data.rule,
        action: data.action,
      },
      { apiEnv: env },
    )

    if (response?.data) {
      if (this.activeScheduleAlert) {
        this.activeScheduleAlert = {
          ...this.activeScheduleAlert,
          rules: [...(this.activeScheduleAlert?.rules ?? []), ...[response.data]],
        }
      }
    }

    this.loading = false

    return response
  }

  @Environment()
  public async editScheduleAlertRule(env: string, data: ScheduleAlertRule) {
    this.loading = true

    const response = await this.$axios.put(
      `/api/v1/tasks/rulesets/${data.ruleSetUid}/rules/${data.uid}`,
      {
        rule: data.rule,
        action: data.action,
      },
      { apiEnv: env },
    )

    if (response?.data) {
      const rules = [
        ...(this.activeScheduleAlert?.rules?.filter((rule: ScheduleAlertRule) => rule.uid !== data.uid) ?? []),
        ...[response.data],
      ]

      if (this.activeScheduleAlert) {
        this.activeScheduleAlert = {
          ...this.activeScheduleAlert,
          rules: rules,
        }
      }
    }

    this.loading = false

    return response
  }

  @Environment()
  public async deleteScheduleAlertRule(env: string, data: ScheduleAlertRule) {
    this.loading = true

    const response = await this.$axios.delete(`/api/v1/tasks/rulesets/${data.ruleSetUid}/rules/${data.uid}`, {
      apiEnv: env,
    })

    if (response?.data) {
      const rules = [
        ...(this.activeScheduleAlert?.rules?.filter((rule: ScheduleAlertRule) => rule.uid !== data.uid) ?? []),
      ]

      if (this.activeScheduleAlert) {
        this.activeScheduleAlert = {
          ...this.activeScheduleAlert,
          rules: rules,
        }
      }
    }

    this.loading = false

    return response
  }
}
