import { orderBy, uniqBy } from 'lodash-es'

import { Pinia, Store } from 'pinia-class-component'

import { Environment } from '@jouzen/outo-apps-toolkit'

import { AppStore, PrefsStore } from '#stores'

import { BatchJob, File, Log, Preview, Result, Sample, Task, TaskDetails, TaskSettings, Warning } from '#types'

let logsToken = ''

const TASK_LIMIT = 10
const LOGS_LIMIT = 500

interface ListResultsParams {
  jobId: string
  taskDetails: TaskDetails
}

interface ListSamplesParams {
  jobId: string
  taskDetails: TaskDetails
}

interface GetTaskDetailsParams {
  uid: string
  taskName: string
}

@Store
export class TasksStore extends Pinia {
  public show = 0

  public loading = false
  public fetching = false

  public activeTab = ''
  public activeTask = ''

  public searchText = ''
  public jzlogsSource = ''

  public warningStatus = false

  public logs: Log[] = []
  public samples: Sample[] = []
  public preview: Preview[] = []

  public results: Result[][] = []
  public warnings: Warning[][] = []

  public fullList: Task[] = []
  public filterList: Task[] = []

  public filterFields: string[] = []

  public activeTaskDetails: TaskDetails | null = null

  public taskDetails: { [taskUid: string]: TaskDetails } = {}

  public get more() {
    return !!logsToken
  }

  public get list() {
    return this.filterList.slice(0, this.show + TASK_LIMIT)
  }

  public get task() {
    return this.fullList?.find((t: Task) => t.uid === this.activeTask)
  }

  public get count() {
    return this.filterList.length
  }

  public get state() {
    return this.activeTaskDetails?.status ?? 'UNKNOWN'
  }

  public showMore() {
    if (this.show + TASK_LIMIT <= this.filterList.length) {
      this.show += TASK_LIMIT
    } else {
      this.show = this.filterList.length
    }
  }

  public setPreview(preview: string[]) {
    this.preview = preview?.map((u: string) => ({ uuid: u.split(':')[0], fw: u.split(':')[1], os: u.split(':')[3] }))
  }

  public setActiveTab(tab: string) {
    this.activeTab = tab
  }

  public setActiveTask(uid: string | null) {
    this.activeTask = uid ?? ''

    this.logs = []
    this.samples = []
    this.results = []
    this.warnings = []
  }

  @Environment()
  public async listTasks(env: string, initial: boolean) {
    if (initial) {
      this.loading = true

      this.fullList = []
      this.filterList = []
    }

    const pathAppJzlogs = `/api/v1/tasks/poirot-analyze-firmware`
    const pathFactoryJzlogs = `/api/v1/tasks/poirot-analyze-factory-jzlogs`

    const resTlog: any = await this.$axios.get(`${pathAppJzlogs}`, { params: { limit: 50 }, apiEnv: env })

    const resFactoryJzlogs: any = await this.$axios.get(`${pathFactoryJzlogs}`, { params: { limit: 50 } })

    this.fullList = orderBy([...(resTlog?.data || []), ...(resFactoryJzlogs?.data || [])], 'createdAt', 'desc')

    await this.updateTasksList()

    if (initial) {
      this.loading = false
    }
  }

  public async filterTasks(options: { source?: string; search?: string; filters?: string[] }) {
    if (
      (options.search !== undefined && options.search !== this.searchText) ||
      (options.source !== undefined && options.source !== this.jzlogsSource) ||
      (options.filters !== undefined && options.filters.join(',') !== this.filterFields.join(','))
    ) {
      this.activeTask = ''

      this.searchText = options.search ?? this.searchText
      this.jzlogsSource = options.source ?? this.jzlogsSource
      this.filterFields = options.filters ?? this.filterFields

      await this.updateTasksList()
    }
  }

  @Environment()
  public async loadLogs(env: string, data: { jobId: string; jobIndex: string; jobType: string }, more?: boolean) {
    if (!more) {
      this.logs = []
    }

    this.fetching = true

    let path = `/api/v1/tasks/batch-jobs/${data.jobType}/${data.jobId}/logs?limit=${LOGS_LIMIT}&startFromHead=false`

    if (data.jobIndex !== undefined) {
      path += `&index=${data.jobIndex}`
    }

    if (more && logsToken?.length > 1) {
      path += `&continuationToken=${logsToken}`
    }

    const response = await this.$axios.get(`${path}`, { apiEnv: env })

    if (more) {
      this.logs = response?.data?.events.concat(this.logs)
    } else if (response?.data?.events) {
      this.logs = response?.data?.events
    }

    logsToken = response?.data?.events?.length || 0 < LOGS_LIMIT ? null : response?.data?.nextBackwardToken

    this.fetching = false

    return response?.data
  }

  public async listSamples(params: ListSamplesParams) {
    this.samples = []

    const task = this.fullList.find((t: Task) => t.uid === params.jobId)

    if (task && params.taskDetails.batchJobs.length > 0) {
      this.loading = true

      const users = params.taskDetails.batchJobs
        ?.find((bj: BatchJob) => bj.jobType === 'poirot-analyze-firmware')
        ?.jobSettings.containerOverrides.environment.filter((e: { name: string; value: string }) =>
          e.name.includes('INPUT_USERS_'),
        )
        .map((e: { name: string; value: string }) => 'users_samples/' + e.value)

      const samples = users?.map((path: string) => ({
        file: path.split('/').pop() ?? '',
        path: path,
      }))

      this.loading = false

      if (this.activeTask === params.jobId) {
        this.samples = samples ?? []

        return this.samples
      }
    }

    return []
  }

  @Environment()
  public async listResults(env: string, params: ListResultsParams) {
    this.results = []
    this.warnings = []

    this.loading = true

    const task = this.fullList.find((t: Task) => t.uid === params.jobId)

    const summaryId = params.taskDetails?.batchJobs?.find((bj) => bj.jobType === 'poirot-summary-analysis')?.uid
    const analyzeId = params.taskDetails?.batchJobs.find((bj) => bj.jobType !== 'poirot-summary-analysis')?.uid

    const spath = `/api/v1/files?search_prefix=summary/${summaryId}&bucket=results`

    const summary = await this.$axios.get(`${spath}`, { apiEnv: env })

    const results = [summary?.data?.contents || []]

    if (task && analyzeId) {
      const apath = `/api/v1/files?search_prefix=analyze/${analyzeId}&bucket=results`

      const analyze = await this.$axios.get(`${apath}`, { apiEnv: env })

      results.push(analyze?.data?.contents || [])
    }

    this.loading = false

    if (params.jobId === this.activeTask) {
      this.results = results

      this.warningStatus = results
        .flat()
        .some((file: Result) => file.key.includes('warnings') && file.key.includes('json'))

      return results
    }

    return []
  }

  @Environment()
  public async createTask(env: string, task: TaskSettings) {
    this.loading = true

    delete task.taskName // If re-run task

    const taskName = this.jzlogsSource === 'factory' ? 'poirot-analyze-factory-jzlogs' : 'poirot-analyze-firmware'

    const response = await this.$axios.post(`/api/v1/tasks/${taskName}`, task, { apiEnv: env })

    if (response?.data?.task) {
      this.listTasks(env, true)

      this.activeTask = response?.data?.task?.uid
    }

    this.loading = false

    return response
  }

  @Environment()
  public async uploadFile(env: string, task: TaskSettings) {
    const formData = new FormData()

    formData.append('sample_file', task.file)

    const response = await this.$axios.post(
      `/api/v1/tasks/samples/upload?analyzeCommand=${task.parameters.inputCommand}${
        task.parameters.blacklistedFilters ? `&blacklistedFilters=${task.parameters.blacklistedFilters}` : ''
      }`,
      formData,
      { apiEnv: env },
    )

    if (response?.data) {
      const { sampleCount, sampleId, samplePath, osSplit, versions, userCount, deviceModels } = response.data

      task.parameters['sampleCount'] = sampleCount
      task.parameters['samplePath'] = samplePath
      task.parameters['sampleId'] = sampleId
      task.parameters.osSplit = osSplit?.includes('android')
        ? `ios:${100 - osSplit?.split(':')[1]}`
        : `ios:${osSplit?.split(':')[1]}`

      task.parameters.fwVersions = versions.join(',')

      task.overrideSteps = ['generateUsersSample']

      task.parameters.maxUsers = userCount
      task.parameters.deviceModels = deviceModels

      return true
    } else {
      return false
    }
  }

  @Environment()
  public async cancelTask(env: string, job: Task) {
    const body = {
      operation: 'terminate',
      reason: 'User aborted',
    }

    const response = await this.$axios.put(`/api/v1/tasks/${job.taskName}/${job.uid}`, body, { apiEnv: env })

    this.listTasks(env, true)

    return response
  }

  @Environment()
  public async previewSample(env: string, sample: Sample) {
    this.loading = true

    const data = await this.getDownloadUrl(env, { bucket: 'samples', prefix: sample.path })

    if (data?.fileUrl) {
      const response = await fetch(data.fileUrl)

      if (response.status === 200) {
        const json = await response.json()

        this.setPreview(json?.users || [])
      }
    }

    this.loading = false
  }

  @Environment()
  public async getFilesList(env: string, data: File) {
    this.loading = true

    const path = `/api/v1/files?search_prefix=${data.prefix}&bucket=${data.bucket}`

    const response = await this.$axios.get(`${path}`, { apiEnv: env })

    this.loading = false

    return response?.data?.contents
  }

  @Environment()
  public async getSamplesDownloadUrl(data: { keys: string[]; bucket: string; saveAs: string }) {
    this.loading = true

    const path = '/api/v1/files/url'

    const payload: { key: string[]; bucket: string; save_as?: string } = {
      key: data.keys,
      bucket: data.bucket,
      save_as: data.saveAs,
    }

    const response = await this.$axios.post(path, payload)

    this.loading = false

    return response?.data
  }

  @Environment()
  public async getDownloadUrl(env: string, data: File) {
    this.loading = true

    const path = '/api/v1/files/url'

    const payload: { key: string[]; bucket: string; save_as?: string } = {
      key: [data.prefix],
      bucket: data.bucket,
    }

    if (data.saveAs) {
      payload.save_as = data.saveAs
    }

    const response = await this.$axios.post(path, payload, { apiEnv: env })

    this.loading = false

    return response?.data
  }

  @Environment()
  public async getTaskDetails(env: string, params: GetTaskDetailsParams) {
    this.loading = true

    const path = `/api/v1/tasks/${params.taskName}/${params.uid}`

    const response = await this.$axios.get(path, { apiEnv: env })

    this.loading = false

    if (response?.data) {
      this.taskDetails[response?.data.uid] = response?.data

      // We trust that latest call to this function means this is the active task.
      if (JSON.stringify(response?.data) != JSON.stringify(this.activeTaskDetails)) {
        this.activeTaskDetails = response?.data
      }
    }

    return response?.data
  }

  @Environment()
  public async getWarningsJson(env: string, data: (Result[] | [])[]) {
    this.loading = true

    let index = 0

    const all: Warning[][] = [[], []]

    const warningsFilters = data.map((d: Result[] | []) =>
      d.filter((file: Result) => {
        return file?.key?.includes('warnings') && file?.key?.includes('json')
      }),
    )

    for (const warnings of warningsFilters) {
      for (const warning of warnings) {
        const file_url = await this.getDownloadUrl(env, { bucket: 'results', prefix: warning.key })

        if (file_url?.fileUrl) {
          const response = await fetch(file_url.fileUrl)

          if (response.status === 200) {
            const warningData = await response.json()

            for (const [key, value] of Object.entries(warningData)) {
              if (key !== 'summary') {
                all[index].push({
                  type: key,
                  key: warning.key,
                  size: warning.size,
                  data: (value as any).data,
                  details: (value as any).data_details,
                  message: (value as any).message,
                })
              }
            }
          }
        }
      }
      index += 1
    }

    this.warnings = all

    this.loading = false
  }

  private async updateTasksList() {
    const appStore = new AppStore()
    const prefsStore = new PrefsStore()

    let filterLists: Task[] | [] = this.fullList ?? []

    if (this.filterFields.length > 0) {
      this.filterFields.forEach((field) => {
        switch (field) {
          case 'name':
            filterLists = filterLists.filter((list: Task) => list.info?.name)
            break

          case 'email':
            filterLists = filterLists.filter((list: Task) => list.initiatingUserEmail === appStore.user?.email)
            break

          case 'sample':
            filterLists = filterLists.filter((list: Task) => list.parameters.analyzeSampleMode === this.activeTab)
            break

          case 'binary':
            filterLists = filterLists.filter((list: Task) => list.parameters.inputCommand === prefsStore.runCommand)
            break

          case 'schedule':
            filterLists = filterLists.filter((list: Task) => list.initiatingUserEmail === 'scheduled')
            break
        }
      })
    }

    if (this.searchText) {
      const searchKeys: string[] = this.searchText.replace(' ', '').split(/,+/)

      searchKeys.forEach((key: string) => {
        filterLists = filterLists.filter(
          (task: Task) =>
            task?.uid?.includes(key) ||
            task.parameters.hwType === key.toLowerCase().replaceAll(' ', '').replace('gen3', 'gen2x') ||
            (/^\d+\.\d*\.*\d*/gi.test(key) && task.parameters.fwVersions?.includes(key)),
        )
      })
    }

    if (this.jzlogsSource) {
      filterLists =
        this.jzlogsSource === 'factory'
          ? filterLists.filter((list) => list.taskName === 'poirot-analyze-factory-jzlogs')
          : filterLists.filter((list) => list.parameters.appFlavor === this.jzlogsSource)
    }

    this.filterList = uniqBy(filterLists, 'uid')

    if (!this.activeTask || !this.filterList.find((l) => l.uid === this.activeTask)) {
      this.activeTask = this.filterList.length ? this.filterList[0]?.uid : ''

      this.show = 0
    } else {
      const active = this.filterList.findIndex((l) => l.uid === this.activeTask)

      if (active > this.show + TASK_LIMIT) {
        this.show = active
      }
    }
  }
}
