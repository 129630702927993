<template>
  <!-- eslint-disable vue/v-on-handler-style -->

  <v-app-bar v-if="edited">
    <v-btn icon="mdi-arrow-left" @click="$router.push('/rdata/studies')" />

    <v-tooltip
      location="bottom start"
      :text="
        edited.name.length === 0 ? 'Name cannot be empty' : !hasUniqueName ? 'Study with this name already exists' : ''
      "
      :content-class="{ 'bg-error': true, 'd-none': edited.name.length > 0 && hasUniqueName }"
      open-on-focus
    >
      <template #activator="{ props }">
        <v-text-field
          v-bind="props"
          v-model.trim="edited.name"
          style="max-width: 500px"
          class="text-primary font-weight-bold"
          :readonly="!hasWriteRights"
          :append-inner-icon="edited.name.length === 0 || !hasUniqueName ? 'mdi-alert-outline' : ''"
          @update:focused="
            (edited.name.length === 0 || !hasUniqueName) && !$event ? (edited.name = original!.name) : null
          "
        />
      </template>
    </v-tooltip>

    <v-spacer />

    <v-btn
      class="mr-2"
      icon="mdi-chart-bar"
      :disabled="hasDataChanges"
      @click="$router.push('/stats?appFlavor=' + edited.appFlavor + '&labelListId=research:id:' + edited.id)"
    />

    <v-btn
      text="Save changes"
      variant="flat"
      :disabled="!edited.name || isLoading || !hasDataChanges || !hasWriteRights || !hasUniqueName"
      @click="saveChanges()"
    />
  </v-app-bar>

  <v-container v-if="edited">
    <v-alert
      v-if="edited.type === 'default_offering'"
      class="mb-2"
      type="warning"
      text="You’re editing a default offering study"
      density="compact"
      closable
      close-label="Close Alert"
      border="start"
      variant="tonal"
    />
    <v-row>
      <v-col cols="12">
        <v-alert v-if="edited.updatedAt" :color="hasWriteRights ? 'info' : 'warning'">
          <v-row align="center">
            <v-col class="flex-grow-1">
              Last edited by {{ edited.updatedBy }} at
              {{ $dayjs(edited.updatedAt.toDate()).format('HH:mm:ss on DD MMM YYYY') }}
            </v-col>

            <v-col class="flex-shrink-1 text-no-wrap text-right">
              {{ hasWriteRights ? (hasDataChanges ? '(unsaved)' : '(saved)') : '(read-only)' }}
            </v-col>
          </v-row>
        </v-alert>
      </v-col>
    </v-row>

    <v-row class="mb-4">
      <v-col cols="12">
        <v-card flat>
          <v-toolbar>
            <v-toolbar-title>Study group</v-toolbar-title>
          </v-toolbar>

          <v-card-text class="pa-8">
            <v-row>
              <v-col>
                <v-text-field v-model="edited.id" readonly label="ID" />
              </v-col>
            </v-row>

            <div class="text-overline px-3">Study configuration</div>
            <v-row class="align-center">
              <v-col class="d-flex align-center justify-end" cols="4">
                <v-select
                  v-model="edited.appFlavor"
                  readonly
                  label="App flavor"
                  hint="App flavor"
                  :items="appFlavors"
                  :disabled="!hasWriteRights"
                />
              </v-col>

              <v-col class="d-flex align-center justify-end" cols="4">
                <v-text-field
                  v-model="edited.fwVersion"
                  persistent-placeholder
                  label="FW version"
                  hint="Minimum FW version"
                  placeholder="Any"
                  :disabled="!hasWriteRights"
                  :readonly="edited.type === 'default_offering'"
                />
              </v-col>

              <v-col class="d-flex align-center justify-end" cols="4">
                <v-select
                  v-model="edited.ringSizes"
                  multiple
                  persistent-placeholder
                  label="Ring sizes"
                  hint="Allowed ring sizes"
                  placeholder="Any"
                  :items="ringSizes"
                  :disabled="!hasWriteRights"
                  :readonly="edited.type === 'default_offering'"
                />
              </v-col>
            </v-row>

            <div class="text-overline px-3">Study metadata</div>
            <v-row class="align-center">
              <v-col class="d-flex align-center justify-end" cols="8">
                <v-combobox
                  class="managers"
                  chips
                  multiple
                  closable-chips
                  label="Managers (emails)"
                  hint="Managers have permission to edit this study group"
                  :disabled="!hasWriteRights"
                  :model-value="[edited.createdBy].concat(edited.managerEmails || [])"
                  @update:model-value="updateManagers($event)"
                />
              </v-col>

              <v-col cols="4">
                <v-select
                  v-model="edited.memoryLayout"
                  label="Memory layout"
                  :items="memoryLayouts"
                  item-title="title"
                  return-object
                  :disabled="!hasWriteRights"
                  @update:model-value="updateMemoryLayout($event)"
                />
              </v-col>
            </v-row>

            <v-row v-if="$featureEnabled('dataCollectionLabels')">
              <v-col>
                <v-combobox
                  v-model="dataCollectionLabels"
                  v-model:search="searchLabel"
                  :items="allDataCollectionlabels"
                  :hide-no-data="false"
                  label="Data collection labels"
                  hide-selected
                  multiple
                  chips
                  closable-chips
                  clearable
                  hint="Write a label and press enter to add it"
                  :disabled="!hasWriteRights"
                  @update:model-value="updateLabels($event)"
                >
                  <template #no-data>
                    <v-list-item>
                      <v-list-item-title>
                        <strong>{{ searchLabel }}</strong>
                        (New label). Press
                        <kbd>enter</kbd>
                        to create a new one
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-combobox>
              </v-col>
            </v-row>

            <RolloutsEdit v-if="edited.type !== 'default_offering'" :config="edited" :disabled="!hasWriteRights" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-if="edited.appFlavor !== 'release'">
      <v-col cols="12">
        <ManageConfigs :config="edited" :disabled="!hasWriteRights" />
      </v-col>
    </v-row>

    <v-row v-if="edited.appFlavor !== 'release'">
      <v-col cols="12">
        <ManageProtos :config="edited" :disabled="!hasWriteRights" />
      </v-col>
    </v-row>

    <v-row v-if="edited.type !== 'default_offering'">
      <v-col cols="12">
        <ManageMembers :config="edited" :disabled="!hasWriteRights" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
  import { cloneDeep, isEqual, uniq } from 'lodash-es'

  import { Component, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { appFlavors, memoryLayouts, ringSizes } from '#views/rdata/constants'

  import { AppStore, ProtoStore, RdataStore, TeamsStore } from '#stores'

  import { MemoryLayout, Study, StudyUser } from '#types'

  @Component
  class StudyEditor extends Vue {
    @Prop() public tid!: string

    public edited: Study | null = null
    public original: Study | null = null

    public searchLabel: string | null = null

    public dataCollectionLabels: string[] = []

    public readonly ringSizes = ringSizes
    public readonly appFlavors = appFlavors
    public readonly memoryLayouts = memoryLayouts

    protected readonly appStore = new AppStore()

    protected readonly rdataStore = new RdataStore()
    protected readonly teamsStore = new TeamsStore()
    protected readonly protoStore = new ProtoStore()

    public get isLoading() {
      return this.rdataStore.loading || this.teamsStore.loading || this.protoStore.loading
    }

    public get studyGroups() {
      return this.teamsStore.teams
    }

    public get hasUniqueName() {
      return !this.studyGroups.some(
        (study) => study.id !== this.edited?.id && study.name.toLowerCase() === this.edited?.name.toLowerCase(),
      )
    }

    public get hasDataChanges() {
      return (this.edited && !isEqual(this.original, this.edited)) || false
    }

    public get hasWriteRights() {
      return (
        this.appStore.isResearchDataAdmin ||
        this.appStore.user?.email === this.original?.createdBy ||
        this.original?.managerEmails?.includes(this.appStore.user?.email)
      )
    }

    public get allDataCollectionlabels() {
      return this.studyGroups
        .flatMap((group) => group.labels)
        .filter((label, index, array) => label && array.indexOf(label) === index)
        .sort((a, b) => a.localeCompare(b))
    }

    @Watch('studyGroups', { immediate: true })
    protected async studyGroupsChanged() {
      const team = this.studyGroups.find((t) => t.id === this.tid)

      if (team) {
        this.original = team
        this.dataCollectionLabels = team.labels

        this.edited = cloneDeep(this.original)

        this.edited!.createdAt = this.original!.createdAt
        this.edited!.updatedAt = this.original!.updatedAt

        await this.teamsStore.fetchLabelUsers(team, team.appFlavor)
      }
    }

    public async saveChanges() {
      const addedUsers = this.edited!.studyUsers.filter(
        (u: StudyUser) => !this.original!.studyUsers.find((su: StudyUser) => su.id === u.id),
      ).map((u: StudyUser) => u.id)

      if (addedUsers.length) {
        const otherTeams = this.studyGroups.filter((studyGroup: Study) => studyGroup.id !== this.edited!.id)

        for (const team of otherTeams) {
          const filteredStudyUsers = team.studyUsers.filter((u: StudyUser) => !addedUsers.includes(u.id))

          if (team.studyUsers.length !== filteredStudyUsers.length) {
            team.studyUsers = filteredStudyUsers

            await this.teamsStore.updateTeam(team.appFlavor, { ...team })
          }
        }
      }

      await this.teamsStore.updateTeam(this.edited!.appFlavor, { ...this.edited })
    }

    public updateLabels(labels: string[]) {
      if (this.edited) {
        this.edited.labels = uniq([...labels])

        this.edited = { ...this.edited }
      }
    }

    public updateManagers(managers: string[]) {
      this.edited!.managerEmails = uniq(
        managers.filter((m) => m !== this.original!.createdBy && m.includes('@ouraring.com')).map((m) => m.trim()),
      )

      this.edited = { ...this.edited! }
    }

    public updateMemoryLayout(memoryLayout: MemoryLayout) {
      if (this.edited) {
        this.edited = { ...this.edited, memoryLayout }
      }
    }
  }

  export default toNative(StudyEditor)
</script>

<style lang="scss" scoped>
  .managers {
    :deep(.v-input__append-inner) {
      display: none;
    }

    :deep(.v-chip:first-child) {
      button {
        display: none;
      }
    }
  }
</style>
